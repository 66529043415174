/* eslint-disable global-require */

import get from 'lodash/get'

const englishDocs = {
  esign: require('@/assets/docs/esign.pdf'),
  'privacy-notice': require('@/assets/docs/privacy-notice.pdf'),
  'terms-and-conditions': require('@/assets/docs/terms-and-conditions.pdf'),
  'communications-policy': require('@/assets/docs/communications-policy.pdf'),
  contact: require('@/assets/docs/contact.pdf')
}

const spanishDocs = {
  esign: require('@/assets/docs/esign-spanish.pdf'),
  'privacy-notice': require('@/assets/docs/privacy-notice-spanish.pdf'),
  'terms-and-conditions': require('@/assets/docs/terms-and-conditions-spanish.pdf'),
  'communications-policy': require('@/assets/docs/communications-policy-spanish.pdf'),
  contact: require('@/assets/docs/contact-spanish.pdf')
}

export const docs = (lang, path) => {
  return get(lang === 'Spanish' ? spanishDocs : englishDocs, path, '')
}
