<template>
  <Main center contentFlex>
    <div class="terms-preview">
      <div v-if="!instantAgreement" class="terms-preview__header">
        <Button large link icon @click="$router.go(-1)">
          <template v-slot:icon>
            <svg width="7" height="11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6 1L1.75 5.25 6 9.5"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </template>

          <span>{{ getLocal(language, 'back') }}</span>
        </Button>
      </div>

      <div v-if="pdfUrl" class="terms-preview__pdf">
        <div class="terms-preview__pdf-wrapper">
          <PDF v-for="i in numPages" :key="i" :src="pdfUrl" style="width: 100%" :page="i">
            <template v-slot:loading> loading... </template>
          </PDF>
        </div>
      </div>
    </div>

    <template v-slot:footer>
      <Button class="terms-preview__submit" large dataElement="agreeContract" expanded @click="cta">{{
        instantAgreement ? `Agree` : getLocal(language, 'back')
      }}</Button>
    </template>
  </Main>
</template>

<script>
import { docs } from '../data/docs'
import { useStore } from 'vuex'
import { getLocal } from '../data/locales'

import { ref } from 'vue'
import { useRoute } from 'vue-router'

import pdfvuer from 'pdfvuer'
import Button from '../components/common/Button.vue'
import Main from '../components/Main.vue'

export default {
  name: 'TermsPreview',
  setup() {
    const urlParams = new URLSearchParams(window.location.search)

    const route = useRoute()
    const store = useStore()
    const language = store.getters.getLanguage

    return {
      pdfUrl: urlParams.get('url') || docs(language, route.params.slug) || route.query.url,
      instantAgreement: urlParams.get('instantAgreement') || route.query.instantAgreement,
      numPages: ref(0),
      store
    }
  },
  components: {
    Button,
    Main,
    PDF: pdfvuer
  },
  methods: {
    getLocal,
    docs,
    cta() {
      if (this.instantAgreement) {
        this.store.commit(this.instantAgreement, { value: true })
      }

      this.$router.go(-1)
    }
  },
  computed: {
    language() {
      return this.$store.getters.getLanguage
    }
  },
  async mounted() {
    if (this.pdfUrl) {
      const pdfdata = pdfvuer.createLoadingTask(this.pdfUrl)

      pdfdata.then((pdf) => (this.numPages = pdf.numPages))
    }
  }
}
</script>

<style lang="scss" scoped>
.terms-preview {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  &__header {
    text-align: left;
  }

  &__pdf {
    border: 1px dashed #b0b7c3;
    flex: 1 0 auto;
    width: 100%;
    height: 400px;
    overflow: auto;
  }

  &__pdf-wrapper {
    width: 100%;
  }
}
</style>
